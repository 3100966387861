<template>
	<div class="main">
		<h1>Landing</h1>
		<p>Лаба 1: <router-link to="/lab1">/lab1</router-link></p>
	</div>
</template>

<style lang="scss" scoped>
.main {
	padding: 10px;
}
</style>
