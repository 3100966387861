<template>
	<router-view />
</template>

<style lang="scss">
@import '/styles/global.scss';

body,
html {
	margin: 0;
	padding: 0;
}

#app {
	// 	font-family: Avenir, Helvetica, Arial, sans-serif;
	// 	-webkit-font-smoothing: antialiased;
	// 	-moz-osx-font-smoothing: grayscale;
	// 	text-align: center;
	// 	color: #2c3e50;
}
</style>
